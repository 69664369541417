import React from 'react';
import Layout from '../../components/Shared/Layout';
import Verticals from '../../components/Verticals';
import '../../assets/css/verticals.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;
// imprt 'bootstrap';

class HealthPage extends React.Component {
  /* constructor(props) {
      super(props);
  } */
  componentDidMount() {
    ReactGA.set({
      title: 'Verticals/Health.Tech',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <Verticals
          edtech={false}
          games={false}
          vr={false}
          iot={false}
          healthtech
          fintech={false}
          consider_prop
        />
      </Layout>
    );
  }
}

export default HealthPage;
